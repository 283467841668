import gql from 'graphql-tag';

export const LOAD_BRAND = gql`
    query($id: ID) {
        brand(id: $id) {
            id
            name
            logo
        }
    }
`;

export const LOAD_BRANDS = gql`
  query {
    brands {
      id
      name
      logo
    }
  }
`;

export const LOAD_USERS = gql`
  query {
    users {
      id
      firstName
      lastName
    }
  }
`;

export const LOAD_BRAND_DATACONFIG_DOWNLOADS = gql`
  query ($id: ID) {
    brand(id: $id) {
      id
      dataConfig {
        reportingTerm
      }
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export const LOAD_BRAND_PROSPECT_DATA_V2 = gql`
  query (
    $id: ID
    $periodStart: String
    $periodEnd: String
  ) {
    brand(id: $id) {
      prospectData(
        periodOneStart: $periodEnd
        periodOneEnd: $periodEnd
        periodTwoStart: $periodStart
        periodTwoEnd: $periodStart
      ) {
        enabled
        currentPeriod
        previousPeriod
        change
      }
    }
  }
`;

export const LOAD_BRAND_PROSPECTS_DATA = gql`
  query (
    $id: ID
    $periodOneStart: String
    $periodOneEnd: String
    $periodTwoStart: String
    $periodTwoEnd: String
  ) {
    brand(id: $id) {
      id
      loyalist {
        id
        contents
      }
      prospect {
        id
        contents
      }
      cheerleader {
        id
        contents
      }
      casual {
        id
        contents
      }
      dataConfig {
        prospects {
          users
          costPer
        }
      }
      prospectData(
        periodOneStart: $periodOneStart
        periodOneEnd: $periodOneEnd
        periodTwoStart: $periodTwoStart
        periodTwoEnd: $periodTwoEnd
      ) {
        enabled
        currentPeriod
        previousPeriod
        change
      }
      segmentMigrationData(
        type: "SEGMENT_PROSPECTS"
        periodOneStart: $periodOneStart
        periodOneEnd: $periodOneEnd
        periodTwoStart: $periodTwoStart
        periodTwoEnd: $periodTwoEnd
      ) {
        to {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        from {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        stayed {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
      }
    }
  }
`;

export const LOAD_BRAND_SEGMENT_DATA = gql`
  query (
    $id: ID
    $type: String
    $periodOneStart: String
    $periodOneEnd: String
    $periodTwoStart: String
    $periodTwoEnd: String
  ) {
    brand(id: $id) {
      id
      segmentData(type: $type) {
        metricData(
          type: $type
          startPeriod: $periodOneStart
          endPeriod: $periodOneEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
          avgOrderValue
          avgOrderCount
          avgOrderTimeDiff
          topProducts {
            sku
            price
            count
          }
        }
        metricDataCompare: metricData(
          type: $type
          startPeriod: $periodTwoStart
          endPeriod: $periodTwoEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
          avgOrderValue
          avgOrderCount
          avgOrderTimeDiff
          topProducts {
            sku
            price
            count
          }
        }
      }
      segmentMigrationData(
        type: $type
        periodOneStart: $periodOneStart
        periodOneEnd: $periodOneEnd
        periodTwoStart: $periodTwoStart
        periodTwoEnd: $periodTwoEnd
      ) {
        to {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        from {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        stayed {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
      }
    }
  }
`;

export const LOAD_BRAND_DATA = gql`
  query ($id: ID) {
    brand(id: $id) {
      id
      firstDataRecordDate
      lastDataRecordDate
      dataConfig {
        id
        type
        dataFormat
        dataVersion
        reportingTerm
        gaView
        prospects {
          costPer
          users
        }
        casuals {
          minOrders
          maxOrders
          marginPer
          costPer
          targetAquisition
        }
        loyalists {
          minOrders
          maxOrders
          marginPer
          costPer
        }
        cheerleaders {
          minOrders
          maxOrders
          marginPer
          costPer
        }
      }
    }
  }
`;

export const LOAD_BRAND_DATACONFIG = gql`
  query ($id: ID) {
    brand(id: $id) {
      id
      name
      dataConfig {
        id
        type
        dataFormat
        dataVersion
        reportingTerm
        gaView
        prospects {
          costPer
          users
        }
        casuals {
          minOrders
          maxOrders
          marginPer
          costPer
          targetAquisition
        }
        loyalists {
          minOrders
          maxOrders
          marginPer
          costPer
        }
        cheerleaders {
          minOrders
          maxOrders
          marginPer
          costPer
        }
      }
    }
  }
`;

export const LOAD_BRAND_DASHBOARD_DATA = gql`
  query ($id: ID) {
    brand(id: $id) {
      id
      name
      loyalist {
        id
        contents
      }
      prospect {
        id
        contents
      }
      cheerleader {
        id
        contents
      }
      casual {
        id
        contents
      }
      firstDataRecordDate
      lastDataRecordDate
      dataConfig {
        reportingTerm
        prospects {
          users
          costPer
        }
      }
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export const LOAD_BRAND_DASHBOARD_STRATEGY = gql`
  query ($id: ID) {
    me {
      id
    }
    brand(id: $id) {
      id
      name
      loyalist {
        id
        contents
      }
      prospect {
        id
        contents
      }
      cheerleader {
        id
        contents
      }
      casual {
        id
        contents
      }
      dataConfig {
        reportingTerm
        prospects {
          users
          costPer
        }
      }
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export const LOAD_BRAND_DASHBOARD_ECOM = gql`
  query ($id: ID, $endDate: String, $startDate: String) {
    brand(id: $id) {
      id
      ecomStats(
        endDate: $endDate
        startDate: $startDate
      ) {
        month
        traffic
        conversionRate
        transactions
        averageOrderValue
        sales
        shipping
        refunds
        directSales
        refundsPercentage
      }
    }
  }
`;

export const LOAD_BRAND_AFFINITYLADDER = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      affinityLadder {
        id
        type
        contents
      }
    }
  }
`;

export const LOAD_BRAND_CONSUMERFINDINGS = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      consumerFindings {
        id
        type
        contents
      }
    }
  }
`;

export const LOAD_BRAND_CONSUMERFINDINGS_DOWNLOAD = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      downloads {
        consumerFindings
      }
      consumerFindings {
        id
        type
        contents
      }
    }
  }
`;

export const LOAD_BRAND_SEGMENT = gql`
  query($id: ID, $type: String) {
    brand(id: $id) {
      id
      name
      segment(type: $type) {
        id
        type
        contents
      }
    }
  }
`;

export const LOAD_BRAND_USERS = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const ADD_BRAND = gql`
  mutation($input: BrandInput) {
    addBrand(input: $input)
  }
`;

export const UPDATE_BRAND = gql`
  mutation($input: BrandInput) {
    updateBrand(input: $input)
  }
`;

export const REMOVE_BRAND = gql`
  mutation($id: ID) {
    removeBrand(id: $id)
  }
`;

export const ADD_BRAND_USER = gql`
  mutation($brand: ID, $email: String, $role: String) {
    addBrandUser(brand: $brand, email: $email, role: $role)
  }
`;

export const UPDATE_BRAND_USER = gql`
  mutation($brand: ID, $user: ID, $role: String) {
    updateBrandUser(brand: $brand, user: $user, role: $role)
  }
`;

export const REMOVE_BRAND_USER = gql`
  mutation($brand: ID, $user: ID) {
    removeBrandUser(brand: $brand, user: $user)
  }
`;

export const LOAD_ROLES = gql`
  query {
    roles
  }
`;

export const LOAD_BRAND_USER = gql`
  query($id: ID, $user: ID) {
    brand(id: $id) {
      id
      user(id: $user) {
        id
        email
        role
      }
    }
    roles
  }
`;

export const PARSE_THEMES = gql`
  mutation($file: String) {
    parseThemes(file: $file)
  }
`;

export const PARSE_SU = gql`
  mutation($file: String) {
    parseStoryUniverse(file: $file)
  }
`;

export const PARSE_METRICS = gql`
  mutation($file: String!, $brand: ID!) {
    metricsParse(file: $file, brand: $brand)
  }
`;

export const LOAD_ME = gql`
  query {
    me {
      id
      email
    }
  }
`;

export const LOAD_ME_BRAND = gql`
  query($id: ID!) {
    me {
      id
      email
    }
    brand(id: $id) {
      id
    }
  }
`;

export const UPDATE_ME = gql`
  mutation($input: UserInput) {
    updateMe(input: $input) {
      id
    }
  }
`;

export const UPDATE_DOC = gql`
  mutation($input: DocumentInput) {
    updateDoc(input: $input)
  }
`;

export const UPDATE_BRAND_DOWNLOADS = gql`
  mutation($id: ID, $input: BrandDownloadsInput) {
    updateBrandDownloads(id: $id, input: $input)
  }
`;

export const LOAD_BRAND_DOWNLOADS = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      downloads {
        stakeholderFindings
        consumerFindings
        customerActivationCycle
        loyaltyArchitecture
        loyaltyBusinessPlan
        brandBook
      }
    }
  }
`;

export const LOAD_BRAND_FIVEBONDS = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      fiveBonds {
        id
        type
        contents
      }
    }
  }
`;

export const LOAD_BRAND_LOYALTYLADDER = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      loyaltyLadder {
        id
        type
        contents
      }
    }
  }
`;

export const LOAD_BRAND_STAKEHOLDERFINDINGS = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      stakeholderFindings {
        id
        type
        contents
      }
    }
  }
`;

export const LOAD_BRAND_STAKEHOLDERFINDINGS_DOWNLOAD = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      downloads {
        stakeholderFindings
      }
      stakeholderFindings {
        id
        type
        contents
      }
    }
  }
`;

export const LOAD_BRAND_SU = gql`
  query($id: ID) {
    brand(id: $id) {
      id
      name
      storyUniverse {
        id
        type
        contents
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation($input: UserInput) {
    addUser(input: $input)
  }
`;

export const UPDATE_USER = gql`
  mutation($input: UserInput) {
    updateUser(input: $input)
  }
`;

export const REMOVE_USER = gql`
  mutation($id: ID) {
    removeUser(id: $id)
  }
`;

export const LOAD_USER = gql`
  query($id: ID) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      type
    }
  }
`;

export const LOGIN = gql`
  mutation ($email: String, $password: String) {
    login(email: $email, password: $password) {
      id
      token
      firstName
      lastName
      type
      roles {
          brand
          role
      }
    }
  }
`;

export const REQUEST_PASSWORD = gql`
  mutation ($email: String) {
    requestPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($password: String, $token: String) {
    resetPassword(password: $password, token: $token)
  }
`;

export const SETUP_USER = gql`
  mutation($input: UserSetupInput) {
    setup(input: $input)
  }
`;

export const LOAD_MIGRATION = gql`
  query (
    $id: ID
    $type: String
    $periodOneStart: String
    $periodOneEnd: String
    $periodTwoStart: String
    $periodTwoEnd: String
  ) {
    brand(id: $id) {
      id
      segmentData(type: $type) {
        metricData(
          type: $type
          startPeriod: $periodOneStart
          endPeriod: $periodOneEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
          avgOrderValue
          avgOrderCount
          avgOrderTimeDiff
          topProducts {
            sku
            price
            count
          }
        }
        metricDataCompare: metricData(
          type: $type
          startPeriod: $periodTwoStart
          endPeriod: $periodTwoEnd
        ) {
          revenue
          revenueAsPercentageOfTotal
          users
          usersAsPercentageOfTotal
          averageIndividualValue
          marginPerUser
          cogsPerUser
          costPerUser
          netRevenuePerUser
          netRevenue
          avgOrderValue
          avgOrderCount
          avgOrderTimeDiff
          topProducts {
            sku
            price
            count
          }
        }
      }
      segmentMigrationData(
        type: $type
        periodOneStart: $periodOneStart
        periodOneEnd: $periodOneEnd
        periodTwoStart: $periodTwoStart
        periodTwoEnd: $periodTwoEnd
      ) {
        to {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        from {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
        stayed {
          lostsouls
          prospects
          casuals
          loyalists
          cheerleaders
        }
      }
    }
  }
`;

export const LOAD_BRAND_SEGMENT_COMPARE = gql`
  query ($id: ID, $type: String) {
    brand(id: $id) {
      id
      name
      firstDataRecordDate
      lastDataRecordDate
      segment(type: $type) {
        id
        type
        contents
      }
      dataConfig {
        reportingTerm
      }
      storyUniverse {
        id
        type
        contents
      }
    }
  }
`;

export const LOAD_BRAND_SEGMENT_REVENUE = gql`
  query (
    $id: ID
    $type: String
    $periodOneStart: String
    $periodOneEnd: String
  ) {
    brand(id: $id) {
      id
      segmentRevenueByMonth(
        type: $type
        startPeriod: $periodOneStart
        endPeriod: $periodOneEnd
      ) {
        revenue
        month
      }
      segmentCountByMonth(
        type: $type
        startPeriod: $periodOneStart
        endPeriod: $periodOneEnd
      ) {
        count
        month
      }
    }
  }
`;