import { useEffect, useRef, useState } from "react"
import notify from "./notify";
import { useGlobal } from "reactn";
import * as store from 'store';

const environment = window.location.hostname === 'theplatform.compassandnail.com'
  ? 'production'
  : 'staging';

const REQUEST_TABLE = `CalculationPeriod-${environment}`;
const COMPLETED_REQUEST_TABLE = `CompletedCalculationRequest-${environment}`;

export const useDataCalculation = (requestIds: string[] = []) => {
  const [global, setGlobal] = useGlobal();
  const [data, setData] = useState<any>(null);

  const dataRef = useRef<boolean>(false);
  const errorRef = useRef<boolean>(false);

  const pollLimit = 300;
  const pollCountRef = useRef<number>(1);

  const batchGetItem = async (requestIds: string[], table: string, key: string) => {
    return await fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API: 'http://localhost:3000'}/dynamo/batchGetItem`, {
      method: 'POST',
      headers: {
        'Authorization': store.get('token'),
        'Content-Type': "application/json"
      },
      body: JSON.stringify({ requestIds, table, key })
    }).then(response => response.json())
  }

  const batchDeleteItem = async (table: string, key: string, values: string[]) => {
    return await fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API: 'http://localhost:3000'}/dynamo/batchDeleteItem`, {
      method: 'POST',
      headers: {
        'Authorization': store.get('token'),
        'Content-Type': "application/json"
      },
      body: JSON.stringify({ values, table, key })
    }).then(response => response.json())
  }

  const pollError = () => {
    batchGetItem(requestIds, REQUEST_TABLE, 'calculationPeriodId')
      .then(res => {
        if (res.Responses && res.Responses[REQUEST_TABLE].length) {
          errorRef.current = true;
          const responses = res.Responses[REQUEST_TABLE];
          notify(
            setGlobal, 
            'Data calculation failed. Please try again or contact us for assistance.', 
            'error'
          );
          batchDeleteItem(
            REQUEST_TABLE, 
            'calculationPeriodId', 
            responses.map(result => result.calculationPeriodId.S)
          );
          console.log(responses);
        } else if (!dataRef.current && pollCountRef.current < pollLimit) {
          new Promise(resolve => setTimeout(resolve, 1000)).then(() => pollError());
        }
      })
  }

  const pollData = () => {
    batchGetItem(requestIds, COMPLETED_REQUEST_TABLE, 'requestId')
      .then(res => {
        if (res.Responses && res.Responses[COMPLETED_REQUEST_TABLE].length === requestIds.length) {
          dataRef.current = true;
          const responses = res.Responses[COMPLETED_REQUEST_TABLE];
          setData(responses.map(result => JSON.parse(result.data.S)));
          console.timeEnd('useDataCalculation ' + JSON.stringify(requestIds))
        } else if (!errorRef.current && pollCountRef.current < pollLimit) {
          pollCountRef.current += 1;
          new Promise(resolve => setTimeout(resolve, 1000)).then(() => pollData());
        }
      })
  }

  useEffect(() => {
    if (requestIds.length) {
      console.time('useDataCalculation ' + JSON.stringify(requestIds))
      fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API: 'http://localhost:3000'}/sqs`, {
        method: 'POST',
        headers: {
          'Authorization': store.get('token'),
          'Content-Type': "application/json"
        },
        body: JSON.stringify({ requestIds, environment })
      }).then(response => response.json())
          .then(results => {
        if (results.every((result: { status: string; }) => result.status === 'fulfilled')) {
          pollCountRef.current = 0;
          dataRef.current = false;
          errorRef.current = false;
          pollData();
          pollError();
        }
      })
    }
  }, [requestIds])

  return {
    data,
  }
}